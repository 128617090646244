/* Contenitore principale */
.editor-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #1e1e1e;
    color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Titoli */
.editor-container h1,
.object-container h2 {
    text-align: left;
    font-size: 1.5rem;
    color: #ffcc00;
    margin-bottom: 10px;
}

/* Sezioni di oggetti */
.object-container {
    margin: 20px 0;
    padding: 15px;
    background-color: #2c2c2c;
    border: 1px solid #444;
    border-radius: 8px;
}

.object-container h2 {
    margin-top: 0;
}

/* Campi input */
.field-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.field-group label {
    font-size: 1rem;
    font-weight: bold;
    color: #f0f0f0;
}

.field-group input,
.field-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #666;
    border-radius: 4px;
    background-color: #2c2c2c;
    color: #ffffff;
    box-sizing: border-box;
    resize: vertical;
}

/* Array - contenitori degli elementi */
.array-item {
    border: 1px solid #444;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #3a3a3a;
}

.array-controls {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

button.remove {
    background-color: #dc3545;
}

button.remove:hover {
    background-color: #b32d3b;
}

/* Layout responsivo */
@media (min-width: 768px) {
    .field-group {
        flex-direction: row;
        align-items: center;
    }

    .field-group label {
        flex: 1;
    }

    .field-group input,
    .field-group textarea {
        flex: 2;
    }
}

#title {
    text-align: center;
}
