.submit {
  background-color: black;
  border-radius: 5px;
  padding: 20px;
}


/* Definisci una dimensione uniforme per i contenitori del portfolio */
#portfolio-wrapper .portfolio-item {
  width: 25%; /* Imposta la larghezza di ogni elemento */
  padding: 10px; /* Aggiungi un po' di margine interno */
  box-sizing: border-box; /* Include padding nel calcolo della larghezza */
}

/* Uniforma le immagini */
#portfolio-wrapper .portfolio-item img {
  width: 100%; /* Adatta l'immagine alla larghezza del contenitore */
  height: 200px; /* Imposta un'altezza fissa */
  object-fit: cover; /* Mantieni il rapporto d'aspetto ritagliando l'immagine se necessario */
  border-radius: 5px; /* Opzionale: aggiunge angoli arrotondati */
}

/* Migliora l'aspetto della griglia */
#portfolio-wrapper {
  display: flex;
  flex-wrap: wrap; /* Permette agli elementi di andare a capo */
  justify-content: space-between; /* Spaziatura uniforme tra gli elementi */
}

#portfolio-wrapper .portfolio-item a {
  display: block;
  text-align: center; /* Centra il testo */
}
